// dependencies.
import React from 'react'
// constants.
import { SITE_URL, TWITTER_USERNAME } from 'src/constants'

const SimpleHead = ({
  path,
  title,
  description,
  keywords,
  thumbnail = 'https://www.exodus.com/img/favicons/apple-icon-152x152.png?v=3',
  appName = 'Exodus',
  socialImg = 'https://www.exodus.com/img/fb-meta-rect.png',
  noindex = false,
}) => {
  const canonicalUrl = path ? `${SITE_URL}/${path}/` : `${SITE_URL}/`
  const twitterUsername = `@${TWITTER_USERNAME}`

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content={noindex ? 'noindex' : 'index'} />
      <meta name="googlebot-news" content="noindex" />
      <meta name="thumbnail" content={thumbnail} />
      <meta name="application-name" content={appName} />
      <link rel="canonical" href={canonicalUrl} />
      {/* iOS */}
      <meta name="apple-mobile-web-app-title" content={title} />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={twitterUsername} />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:src" content={socialImg} />
      {/* Facebook OG */}
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={appName} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={socialImg} />
      <meta property="og:image:secure_url" content={socialImg} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="3200" />
      <meta property="og:image:height" content="1674" />
    </>
  )
}

export default SimpleHead
